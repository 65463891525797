<template lang="pug">
#charterWindowDate.charter-search__window(
  ref="charterWindowDate",
  key="charterWindowDate",
  :class="{ lastminute }"
)
  .charter-search__window-content.overflow-auto.pb-px-150.pb-px-md-20
    PackageSearchBarWindowHeader.d-flex.bg-light-turquoise.mx-lg-0.mb-3.pl-4.pr-2.mx-n3.mx-sm-n4.mt-md-n4.mt-lg-0.py-3(
      @close="$emit('clear')"
    ) {{ $t('charterSelectDate') }}

    .mb-3
      DurationSelectButtons(
        :selected-duration="selectedDateRangeDuration"
        @select="handleSelectedDuration"
      )

    client-only
      .calendar-loading-overlay(v-if="calendarSuggestionsFetchStatus === fetchStatus.fetching")
        Loading

      .d-flex.justify-content-center.mb-5.mt-4
        .d-flex.flex-column
          ScreenWidthProvider(v-slot="{ isNarrower, isWider }")
            DatePicker(
              ref="charterFC",
              v-model:range="calendarValue"
              v-bind="calendarConfig"
              :is-selecting-range="datePickerIsSelectingRange"
              :select-range-days="selectedDateRangeDuration"
              :rows="isNarrower('lg', 1) ? 12 : 1"
              @update:is-selecting-range="handleSelectStatus"
              @dayclick="dayClicked"
              @on-date-range-start="handleStartRangeChange"
              @mouseleave="hoverDate(null)"
            )
              template(#day-content="{ day, dayProps, dayEvents }")
                button.bg-transparent.p-0.d-flex.align-items-center.justify-content-center.position-relative.cursor-pointer.h-100.w-100.rounded-0(
                  v-bind="{ ...dayProps, ...getDayProps(day.date) }"
                  v-on="dayEvents"
                  @mouseenter="isWider('lg') && hoverDate(day.date)"
                )
                  span {{ day.day }}

                  small.position-absolute.right-0.top-0.direct-flight-day.text-black(v-if="hasDayDirectFlights(day.date)")
                    fa(:icon="icons.faPlane")

                  .position-absolute.w-100.h-100.top-0.left-0.z-n3(:class="dayClass(day.date)")

          .d-none.d-lg-flex.pt-4.mb-n4.ml-n2
            div(v-if="directFlightDays.length")
              fa(:icon="icons.faPlane")
              span.ml-3 {{ $t('charterLegendDirectFlight') }}
            .d-flex.ml-auto(v-if="openPurchaseFlightDays.length")
              .p-2.bg-vivid-turquoise.rounded-1
              span.ml-3 {{ $t('charterLegendRecommendedDates') }}

          .bg-white.shadow-lg.bottom-0.position-absolute.z-100.w-100.py-3.px-4.d-lg-none.left-0
            div(v-if="directFlightDays.length")
              fa(:icon="icons.faPlane")
              span.ml-2 {{ $t('charterLegendDirectFlight') }}
            hr.bg-light-gray
            .mt-2.pb-1.d-flex(v-if="openPurchaseFlightDays.length")
              .p-2.bg-vivid-turquoise.rounded-1
              span.ml-2 {{ $t('charterLegendRecommendedDates') }}
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { faPlane } from '@fortawesome/pro-duotone-svg-icons'

import fetchStatus from '@/constants/fetch-status'
import { DATEPICKER_DATE_FORMAT } from '@/constants/date'

const INIT_CALENDAR_VALUE = {
  'currentDate': null,
  'selectedDate': null,
  'selectedDateTime': false,
  'selectedHour': '00',
  'selectedMinute': '00',
  'selectedDatesItem': '',
  'selectedDates': [],
  'dateRange': {
    'start': '',
    'end': ''
  },
  'multipleDateRange': []
}

export default defineNuxtComponent({
  setup () {
    const { localeDate } = useDate()

    return {
      localeDate
    }
  },

  props: {
    maxCalendars: {
      type: Number,
      default: 3
    },

    windowInnerWidth: {
      type: Number,
      default: 0
    },

    closeInsteadOfNext: {
      type: Boolean,
      default: false
    }
  },

  emits: [
    'clear',
    'start-date-change',
    'selected-date-range'
  ],

  data () {
    return {
      icons: {
        faPlane,
      },

      datePickerIsSelectingRange: false,
      datePickerSelectedStartDate: null,
      hoveringDate: null,
      calendarValue: INIT_CALENDAR_VALUE,
      fetchStatus,
    }
  },

  computed: {
    ...mapState(useCharterPackagesStore, {
      calendarSuggestions: 'calendarSuggestions',
      calendarSuggestionsForDepartureDate: 'calendarSuggestionsForDepartureDate',
      lastminute: 'lastminute',
      selectedDateRangeStartDate: 'selectedDateRangeStartDate',
      selectedDateRangeEndDate: 'selectedDateRangeEndDate',
      selectedDateRangeDuration: 'selectedDateRangeDuration',
      calendarSuggestionsFetchStatus: 'calendarSuggestionsFetchStatus',
    }),

    forcedCalendarDays () {
      if (this.datePickerIsSelectingRange) {
        return this.getCalendarDaysFromSuggestions(this.calendarSuggestionsForDepartureDate)
      }
      return this.getCalendarDaysFromSuggestions(this.calendarSuggestions)
    },

    directFlightDays () {
      if (this.datePickerIsSelectingRange) {
        return this.calendarSuggestionsForDepartureDate.direct || []
      }
      return this.calendarSuggestions.direct || []
    },

    openPurchaseFlightDays () {
      if (this.datePickerIsSelectingRange) {
        return this.calendarSuggestionsForDepartureDate.recommended || []
      }
      return this.calendarSuggestions.recommended || []
    },

    calendarConfig () {
      return {
        color: 'solresor',
        minDate: new Date(),
        borderless: true,
        showWeeknumbers: true,
        highlight: true,
        dates: { start: new Date(2024, 5, 5), end: new Date(2024, 5, 10) },
      }
    },
  },

  mounted () {
    if (this.lastminute) {
      this.resetCalendarDates()
      return
    }

    this.calendarValue.dateRange.start = (this.selectedDateRangeStartDate && this.selectedDateRangeEndDate) ? this.$dayjs(this.selectedDateRangeStartDate).format(DATEPICKER_DATE_FORMAT) : ''

    if (this.selectedDateRangeDuration && this.selectedDateRangeStartDate) {
      // non-flex dates
      this.calendarValue.dateRange.end = this.$dayjs(this.selectedDateRangeStartDate).add(this.selectedDateRangeDuration, 'day').format(DATEPICKER_DATE_FORMAT)
    } else if (this.selectedDateRangeEndDate) {
      // flex dates
      this.calendarValue.dateRange.end = this.$dayjs(this.selectedDateRangeEndDate).format(DATEPICKER_DATE_FORMAT)
    }

    if (!this.selectedDateRangeDuration && this.selectedDateRangeStartDate && !this.selectedDateRangeEndDate) {
      this.resetDates()
    }
  },

  methods: {
    ...mapActions(useCharterPackagesStore, {
      setSelectedDateRangeStartDate: 'SET_SELECTED_DATE_RANGE_START_DATE',
      setSelectedDateRangeEndDate: 'SET_SELECTED_DATE_RANGE_END_DATE',
      setSelectedDateRangeDuration: 'SET_SELECTED_DATE_RANGE_DURATION',
      setCalendarSuggestions: 'SET_CALENDAR_SUGGESTIONS',
      setLastMinute: 'SET_LASTMINUTE'
    }),

    resetCalendarDates () {
      this.$nextTick(() => {
        this.calendarValue.dateRange.start = ''
        this.calendarValue.dateRange.end = ''
      })
    },

    resetDates  () {
      this.setSelectedDateRangeDuration(null)
      this.setSelectedDateRangeStartDate('')
      this.setSelectedDateRangeEndDate('')
      this.resetCalendarDates()
    },

    dayClicked ({ date }) {
      if (this.lastminute) {
        this.disableLastMinute()
      }

      if (this.forcedCalendarDays.length > 0 && !this.forcedCalendarDays.includes(date)) {
        return
      }

      this.setDateRange(true)
    },

    hasDayDirectFlights (date) {
      return this.directFlightDays.includes(this.localeDate(date))
    },

    hasDayOpenPurchase (date) {
      return this.openPurchaseFlightDays.includes(this.localeDate(date))
    },

    getDayProps (date) {
      const formattedDate = this.$dayjs(date).format(DATEPICKER_DATE_FORMAT)

      const isDisabled = (
        formattedDate !== this.datePickerSelectedStartDate &&
        !this.forcedCalendarDays.includes(formattedDate)
      )

      let isInHoveringDateRange = false
      let isHoveringDateRangeEdgeStart = false
      let isHoveringDateRangeEdgeEnd = false
      if (this.hoveringDate && this.selectedDateRangeDuration) {
        const dayjsDate = this.$dayjs(date)
        const dayjsHoveringDateRangeStart = this.$dayjs(this.hoveringDate)
        const dayjsHoveringDateRangeEnd = dayjsHoveringDateRangeStart.add(this.selectedDateRangeDuration, 'days')

        isInHoveringDateRange = (
          dayjsDate.isBetween(
            dayjsHoveringDateRangeStart,
            dayjsHoveringDateRangeEnd,
            'days',
            '[]'
          )
        )

        if (isInHoveringDateRange) {
          isHoveringDateRangeEdgeStart = dayjsDate.isSame(dayjsHoveringDateRangeStart)
          isHoveringDateRangeEdgeEnd = dayjsDate.isSame(dayjsHoveringDateRangeEnd)
        }
      }

      return {
        class: {
          'charter-search__day-disabled pe-none': isDisabled,
          'calendar-hovering-date-range': isInHoveringDateRange,
          'calendar-hovering-date-range-edge': isHoveringDateRangeEdgeStart || isHoveringDateRangeEdgeEnd,
          'calendar-hovering-date-range-edge-start': isHoveringDateRangeEdgeStart,
          'calendar-hovering-date-range-edge-end': isHoveringDateRangeEdgeEnd,
        },
        disabled: isDisabled
      }
    },

    setDateRange (emitSelectedDateRangeEvent) {
      this.$nextTick(() => {
        const { start, end } = this.calendarValue.dateRange
        this.setSelectedDateRangeStartDate(start)
        this.setSelectedDateRangeEndDate(end)

        if (this.closeInsteadOfNext) {
          this.$emit('clear')
        } else if (emitSelectedDateRangeEvent && end) {
          this.$emit('selected-date-range')
        }
      })
    },

    disableLastMinute () {
      this.setLastMinute(false)

      const query = { ...this.$route.query }

      delete query.lastminute

      this.$router.push({ query }).catch(() => {})
    },

    getCalendarDaysFromSuggestions (calendarSuggestions) {
      if (!calendarSuggestions.flights) {
        return []
      } else if (!calendarSuggestions.flights.length) {
        // NOTE this makes all the calendar dates to be disabled
        return ['1/1/1970']
      }

      return calendarSuggestions.flights.map(d => this.$dayjs(d).format(DATEPICKER_DATE_FORMAT))
    },

    handleSelectedDuration (duration) {
      this.disableLastMinute()

      if (duration === null) {
        this.resetDates()
      }

      this.setSelectedDateRangeDuration(duration)

      if (duration !== null && this.calendarValue.dateRange.start) {
        this.setDateRange()
      }
    },

    dayClass (date) {
      const classes = []

      if (this.hasDayOpenPurchase(date)) {
        classes.push('bg-vivid-turquoise')
      } else {
        classes.push('bg-light-turquoise')
      }

      return classes.join(' ')
    },

    handleSelectStatus (isSelecting) {
      this.$nextTick(() => (this.datePickerIsSelectingRange = isSelecting))
    },

    handleStartRangeChange (event) {
      this.datePickerSelectedStartDate = event.date
      this.$emit('start-date-change', event.date)
    },

    hoverDate (date) {
      if (!this.selectedDateRangeDuration) {
        return
      }

      this.hoveringDate = date
    }
  }
})
</script>

<style lang='scss' scoped>
@import '@/assets/scss/modules/charter/_charter-search';

.calendar-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.5);
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
